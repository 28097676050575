import request from '@/utils/request'


// 查询物业开锁反馈列表
export function listLockFeedback(query) {
  return request({
    url: '/village/village-lock-feedbacks/list',
    method: 'get',
    params: query
  })
}

// 查询物业开锁反馈分页
export function pageLockFeedback(query) {
  return request({
    url: '/village/village-lock-feedbacks/page',
    method: 'get',
    params: query
  })
}

// 查询物业开锁反馈详细
export function getLockFeedback(data) {
  return request({
    url: '/village/village-lock-feedbacks/detail',
    method: 'get',
    params: data
  })
}

// 新增物业开锁反馈
export function addLockFeedback(data) {
  return request({
    url: '/village/village-lock-feedbacks/add',
    method: 'post',
    data: data
  })
}

// 修改物业开锁反馈
export function updateLockFeedback(data) {
  return request({
    url: '/village/village-lock-feedbacks/edit',
    method: 'post',
    data: data
  })
}

// 删除物业开锁反馈
export function delLockFeedback(data) {
  return request({
    url: '/village/village-lock-feedbacks/delete',
    method: 'post',
    data: data
  })
}
